

































































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class About extends Vue {
  imageUrl = 0;
  graduateImg = 0;
  fileListImg = [];

  // 方法,图片上传钩子
  handleAvatarSuccess(res: any, file: any) {
      console.log(res, file);
  }

  handlePictureCardPreview() {
      this.imageUrl = 1;
  }
}
